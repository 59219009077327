import { Link } from "@remix-run/react";
import { ScrollParallax } from "react-just-parallax";

interface Props {
  Heading?: string;
  SubHeading?: string;
  BtnText?: string;
  BtnLink?: string;
  Shadow?: boolean;
}

export const ContactUs = ({
  Heading,
  SubHeading,
  BtnText,
  Shadow,
  BtnLink = "/contact-us",
}: Props) => (
  <div>
    <div className="justify-between block px-8 pt-24 md:flex ">
      <div className="z-auto">
        <div>
          <div className="relative z-50 p-2 font-bold text-transparent dark:text-white md:text-left bg-clip-text bg-gradient-to-r from-staff-390 to-staff-200 text-blue">
            <div className="text-6xl font-SourceSans">{Heading}</div>
            <div className="text-xl font-medium text-black dark:text-white">
              {SubHeading}
            </div>
          </div>

          {Shadow ? (
            <ScrollParallax enableOnTouchDevice>
              <div
                className={`hidden md:block font-SourceSans font-bold -mt-24 before:whitespace-nowrap md:before:text-[144px] before:tracking-wide  before:text-staff-320 before:opacity-[0.5] before:content-['Lets_Talk']`}
              />
            </ScrollParallax>
          ) : (
            ""
          )}
        </div>
        <Link
          rel="canonical"
          prefetch='render'
          to={BtnLink}
          className="block px-8 py-6 mt-8 text-xl font-normal text-white rounded-full w-max bg-staff-400 hover:bg-staff-410 dark dark:hover:bg-staff-420 font-SourceSans"
        >
          {BtnText}
        </Link>
      </div>
      <div className="relative flex justify-end w-full md:w-96">
        <div className="relative mx-auto">
          <img
            src="/contact/Contactus-img.webp"
            className="relative"
            alt="contact"
          />
        </div>
      </div>
    </div>
  </div>
);
